<template>
  <b-card>
    <FormBuilder
      label="Profile Border"
      create
      :fields="fields"
      :loading-submit="$store.state.interest.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
        },
        {
          key: 'is_available_for_all_user',
          label: 'Is available for all user',
          value: false,
          type: 'switch',
        },
        {
          key: 'url',
          label: 'Upload Border',
          type: 'image',
          required: true,
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('border/create', data)
        .then(callback)
        .catch(error => {
          let message = 'Failed to create border!'
          if (error?.response?.status === 400 && error?.response?.data) {
            message = error?.response?.data
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: message,
              variant: 'danger',
            },
          })
        }) // Pass error if there's a failure
    },
  },
}
</script>

  <style scoped>

  </style>
